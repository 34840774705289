var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 index-app"},[(
      Object.entries(_vm.configObject).length > 0 &&
      _vm.isAdvanced === false &&
      _vm.isGIS === false &&
      _vm.loading === false
    )?_c('MSDAT'):_vm._e(),(
      Object.entries(_vm.configObject).length > 0 &&
      _vm.isAdvanced === true &&
      _vm.isGIS === false &&
      _vm.loading === false
    )?_c('AdvanceMSDAT',{attrs:{"indicators":_vm.configObject.indicators,"dataSources":_vm.configObject.dataSources,"defaultIndicators":_vm.configObject.defaultIndicators,"initialIndicator":_vm.configObject.initialIndicator,"initialDataSource":_vm.configObject.initialDataSource,"initialLocation":_vm.configObject.initialLocation,"showTableRelatedIndicator":_vm.configObject.showTableRelatedIndicator != undefined
        ? _vm.configObject.showTableRelatedIndicator
        : true}}):_vm._e(),(
      Object.entries(_vm.configObject).length > 0 &&
      _vm.isGIS === true &&
      _vm.isAdvanced === false &&
      _vm.loading === false
    )?_c('GisMSDAT',{attrs:{"indicators":_vm.configObject.indicators,"dataSources":_vm.configObject.dataSources,"defaultIndicators":_vm.configObject.defaultIndicators,"initialIndicator":_vm.configObject.initialIndicator,"initialDataSource":_vm.configObject.initialDataSource,"initialLocation":_vm.configObject.initialLocation,"showTableRelatedIndicator":_vm.configObject.showTableRelatedIndicator != undefined
        ? _vm.configObject.showTableRelatedIndicator
        : true}}):_vm._e(),(_vm.showClearDataModal)?_c('ClearDBModal',{staticStyle:{"z-index":"1500"}}):_vm._e(),(_vm.$route.query.prev)?_c('div',{staticClass:"preview"},[_c('b-button',{staticStyle:{"font-size":"1.5rem"},attrs:{"size":"lg","variant":"info"},on:{"click":function($event){return _vm.$router.push('/custom/requests')}}},[_vm._v("Back to Requests")])],1):_vm._e(),(
      _vm.isCustomDashboard &&
      _vm.$store.getters.getEmbedIframe !== null &&
      _vm.$store.getters.getEmbedIframe !== ''
    )?_c('div',{staticClass:"mt-5"},[_c('h1',{staticClass:"url_title"},[_vm._v("Embedded Iframe")]),_c('div',{staticClass:"w-100 url_height"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$store.getters.getEmbedIframe)}})])]):_vm._e(),(
      _vm.isCustomDashboard &&
      _vm.$store.getters.embeddedUrl !== null &&
      _vm.$store.getters.embeddedUrl !== ''
    )?_c('div',{class:[
      _vm.$store.getters.getEmbedIframe === null || _vm.$store.getters.getEmbedIframe === ''
        ? 'url_body_2'
        : 'url_body',
    ]},[_c('h1',{staticClass:"url_title"},[_vm._v("Embedded URL")]),_c('div',{staticClass:"w-100 url_height"},[_c('iframe',{staticStyle:{"width":"100%","height":"800px","border":"none"},attrs:{"src":_vm.$store.getters.getEmbedUrl}})])]):_vm._e(),_c('NewsLetter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }